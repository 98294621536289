// Slideouts
//
// uses:
// - Alpine.js

(function () {
  document.addEventListener('alpine:init', () => {
    Alpine.data('activeSlideoutManager', () => ({
      // data
      activeSlideout: null,
      originalTitle: '',
      
      // private functions
      getDocumentLocationHash() {
        return decodeURI(document.location.hash);
      },
      getLocationSlideoutIfActive() {
        const hash = this.getDocumentLocationHash();
        return hash && document.querySelector(hash + '[data-slideout-element]')
          ? hash.slice(1)
          : null;
      },

      // alpine directives
      init() {
        this.originalTitle = document.title;

        this.$nextTick(() => {
          this.activeSlideout = this.getLocationSlideoutIfActive();
        });
        this.$watch('activeSlideout', (value, oldValue) => {
          if (value !== null) {
            document.location.hash = value;
          } else {
            if (this.getDocumentLocationHash().slice(1) == oldValue) {
              history.pushState(null, null, ' ');
            }
            document.title = this.originalTitle;
          }
        });

        window.addEventListener('hashchange', () => {
          this.activeSlideout = this.getLocationSlideoutIfActive();
        });
      },
    }));
  });
})();
