// Scroll into view
//
// if the designated element is in a scrollable element
// then bring it into view on page load
//
// Usage:
// Add the [data-scroll-into-view] attribute to the element to bring into view;
// for example, an active link in a horizontal navigation menu.
//

(function (window) {
  'use strict';
  
  function findScrollableParent(element) {
    var parent = element.parentElement;
    if (!parent) { return document.body; }
    var overflowX = window.getComputedStyle(parent).overflowX;
    if (
      overflowX !== 'visible' &&
      overflowX !== 'hidden' &&
      parent.scrollWidth > parent.offsetWidth
    ) {
      return parent;
    }
    return findScrollableParent(parent);
  }
  
  // credit: https://stackoverflow.com/a/7557433
  function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  
  function scrollIntoView() {
    var elements = document.querySelectorAll('[data-scroll-into-view]');
    elements.forEach(function (element) {
      if (!isElementInViewport(element)) {
        var scrollParent = findScrollableParent(element);
        if (scrollParent) {
          scrollParent.scrollLeft = element.getBoundingClientRect().left - scrollParent.getBoundingClientRect().left;
        }
      }
    });
  }
  
  document.addEventListener('pjax:success', scrollIntoView);
  scrollIntoView();
}(this));
