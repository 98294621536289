// Glossary Terms
//
// Scan all text nodes in the document for the presence
// of window.ProjectEnv.glossary.terms and if found replace
// term with a link to its definition window.ProjectEnv.glossary.defs
//

(function (window) {
  'use strict';
  
  const contentSelector = '[data-glossary-content]';
  const excludeChildrenOf = [
    'a',
    'button',
    'select',
    'summary',
    'label',
    'style',
    'script',
    'noscript',
    'template',
  ];
  
  function initGlossary() {
    // sanity check
    if (
      typeof window.ProjectEnv === 'undefined' ||
      window.ProjectEnv === null ||
      !window.ProjectEnv.glossary
    ) {
      return;
    }
    
    const terms = Object.keys(window.ProjectEnv.glossary.terms);
    if (terms.length === 0) {
      return;
    }
    
    // proceed
    findTextNodes().forEach(node => {
      
      const needles = new RegExp(`\\b(?:${terms.join('|')})\\b`, 'gi');
      const matches = Array.from(node.data.matchAll(needles)).reverse();
      
      if (matches.length === 0) {
        return;
      }
      
      matches.forEach(match => {
        
        const term = match[0];
        const termDef = window.ProjectEnv.glossary.defs[window.ProjectEnv.glossary.terms[term.toLowerCase()]];
        
        // split text at the term end boundary
        const newSibling = node.splitText(match.index + term.length);
        
        // split text at the term start boundary
        const ignore = node.splitText(match.index);

        // create term link
        const termLink = document.createElement('a');
        termLink.classList.add('glossary-inline-link');
        termLink.href = termDef.url;
        termLink.title = "Lookup " + term + " in the glossary";
        termLink.setAttribute('x-on:click.prevent', 'window.location.hash = "' + termDef.hash + '"');
        termLink.appendChild(document.createTextNode(term));

        // add term to the text
        node.parentNode.replaceChild(termLink, ignore);
      });
    })
  }
  
  
  // locate all text-only nodes inside the content
  // filter out nodes that are a child of `a, button` etc
  function findTextNodes() {
    const nodes = [];
    
    document.querySelectorAll(contentSelector)
      .forEach(content => {
        const walker = document.createTreeWalker(
          content,
          NodeFilter.SHOW_TEXT,
          node => node.parentNode.closest(excludeChildrenOf.join(','))
            ? NodeFilter.FILTER_SKIP
            : NodeFilter.FILTER_ACCEPT
        );
        while (walker.nextNode()) {
          nodes.push(walker.currentNode);
        }
      });
    
    return nodes;
  }
  
  document.addEventListener('pjax:success', initGlossary);
  initGlossary();
}(this));
